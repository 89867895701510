<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">{{showLang('com.export.fn.pole')}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="80">
      <FormItem prop="name" :label="showLang('com.tab.title')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" :placeholder="showLang('com.tab.title')"></Input>
        </div>
      </FormItem>
      <FormItem prop="id" :label="showLang('com.device.pole.code')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" disabled v-model="form.id" :placeholder="showLang('com.sys.generate')"></Input>
        </div>
      </FormItem>
      <FormItem prop="groupId" label="所在分区" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.groupId">
          <template v-for="(s, idx) in selectedGroups">
            <Option v-if="s.id != '0'" :key="idx" :value="parseInt(s.id)">{{s.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="poleType" :label="showLang('com.import.cn.pole.type')" style="margin-right: 15px">
        <Select v-model="form.poleTypeId" style="width: 200px; margin-right: 10px;">
          <Option v-for="(s, idx) in poleTypes" :key="idx" :value="s.id">{{s.name}}</Option>
        </Select>
      </FormItem>
      <FormItem prop="poleType" :label="showLang('com.import.cn.arm.type')" style="margin-right: 15px">
        <Select v-model="form.armTypeId" style="width: 200px; margin-right: 10px;">
          <Option v-for="(s, idx) in armTypes" :key="idx" :value="s.id">{{s.name}}</Option>
        </Select>
      </FormItem>
      <FormItem prop="height" :label="showLang('com.import.cn.pole.height')" style="margin-right: 15px">
        <Input type="number" number v-model="form.height">
        <span slot="append">M</span>
        </Input>
      </FormItem>
      <FormItem prop="lng" :label="showLang('com.import.cn.lng')">
        <Input ref="lng" type="text" v-model="form.lng" :placeholder="showLang('import.lng.installation.location')" style="width: 270px;margin-right: 10px"></Input>
        <Button type="primary" @click="selectPos">选择</Button>
      </FormItem>
      <FormItem prop="lat" :label="showLang('com.import.cn.lat')">
        <Input ref="lat" type="text" v-model="form.lat" :placeholder="showLang('import.lat.installation.location')"></Input>
      </FormItem>
      <FormItem prop="buildDate" :label="showLang('com.date.installation')">
        <DatePicker ref="buildDate" type="date" format="yyyy-MM-dd" v-model="form.buildDate" :placeholder="showLang('com.date.select.installation')" :editable="false"></DatePicker>
      </FormItem>
      <!-- <FormItem prop="location" label="安装地址">
        <Input ref="location" type="text" v-model="form.location" :placeholder="showLang('import.lat.installation.location')"></Input>
      </FormItem> -->
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
    <ModalSelectMapPoint v-model="showMapModal" :custCenter="pos" @posSelected="posSelected" />
    <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalSelectMapPoint from '@/views/common/modals/ModalSelectMapPoint'
export default {
  name: 'ModalPoleEdit',
  components: {
    ModalSelectMapPoint,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    armTypes: {
      type: Array,
      default() { return []; }
    },
    poleTypes: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      showMapModal: false,
      isAdd: false,
      types: [],
      groupName: '',
      mains: [],
      devices: [],
      pos: {
        area: '',
        lat: 0,
        lng: 0,
      },
      form: {
        id: "",
        groupId: 0,
        name: '',
        lng: '',
        lat: '',
        armTypeId: "",
        poleTypeId: '',
        height: 10,
        buildDate: '',
        location: ''
      },
      rules: {
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        // console.log('modal level edit mounted', this.item)
        this.isAdd = this.item.isAdd;
        if (!this.isAdd) {
          this.form.id = this.item.data.id;
          this.form.groupId = this.item.data.groupId;
          this.form.name = this.item.data.name;
          this.form.lng = this.item.data.lng;
          this.form.lat = this.item.data.lat;
          this.form.armTypeId = this.item.data.armTypeId;
          this.form.poleTypeId = this.item.data.poleTypeId;
          this.form.height = this.item.data.height;
          this.form.buildDate = this.item.data.buildDate;
          this.form.location = this.item.data.location;
        } else {
          this.form.id = '';
          this.form.groupId = 0;
          this.form.name = '';
          this.form.lng = "";
          this.form.lat = "";
          this.form.armTypeId = "";
          this.form.poleTypeId = "";
          this.form.height = 10;
          this.form.buildDate = new Date().format('yyyy-MM-dd');
          this.form.location = "";
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('auth', ['config']),
    ...mapState('group', ['selectedGroups']),
    ...mapGetters('auth', ['showLang']),
  },
  mounted: function () {
  },
  methods: {
    selectPos: function(){
      let lat = this.form.lat;
      let lng = this.form.lng;
      if(!lat){
        lat = this.config.lat;
        lng = this.config.lng;
      }
      this.pos = {
        area: this.config.area,
        lat: lat,
        lng: lng,
      };
      this.showMapModal = true;
    },
    posSelected: function(params){
      console.log('pos sel', params, this.config);
      this.form.lat = params.lat;
      this.form.lng = params.lng;
    },
    ok: async function () {
      if (this.form.name.trim().length == 0) {
        this.$Message.warning(this.showLang('save.name'));
        return;
      }
      if (this.form.stationId <= 0) {
        this.$Message.warning(this.showLang('save.site.associated'));
        return;
      }
      // if(this.form.content.group <= 0 || this.form.content.group > 255){
      //   this.$Message.warning('分组号必须在 1 - 255 之间');
      //   return;
      // }
      this.form.buildDate = new Date(this.form.buildDate).format("yyyy-MM-dd")
      this.$axios.post(`//${this.domains.trans}/pole/config/SavePoleInfo`, this.form).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$Message.info(this.showLang('com.tips.save'));
          this.showModal = false;
          this.$emit('saved', { isAdd: this.isAdd, data: res.data });
          window.eventBus.$emit('poleChanged');
        }
      })
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>