<template>
<div class="pole-container">
  <!-- <div class="station-left-area">
    <GroupStationTree />
  </div> -->
  <div class="station-content-area">
    <div class="pole-header">
      <div :class="['header-item', item.code == tab ? 'header-active':'']" v-for="(item, idx) in menus" :key="idx" @click="switchMenu(item.code)">{{item.name}}</div>
    </div>
    <div class="pole-content">
      <template v-for="(item, idx) in menus">
        <component v-if="item.code == tab && item.com" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import ConfigGroupIndex from '@/views/auth/group/Index'
// import ConfigStationIndex from '@/views/pole/config/station/Index'
// import ConfigStationIndex from '@/components/config/station/station/Index'
// import ConfigDeviceIndex from '@/views/pole/config/device/Index'
import ConfigGroupIndex from './group/Index'
// import ConfigStationIndex from '@/components/config/station/station/Index'
// import ConfigDeviceIndex from '@/components/config/station/device/Index'
import ConfigPoleIndex from './pole/Index'
import GroupStationTree from './tree/Index'
import ArmType from './armType/Index'
import PoleType from './poleType/Index'
export default {
  name: 'Home',
  components: {
    GroupStationTree,
  },
  data(){
    return {
      tab: 'sub4',
      menus: [
        {code: 'sub1', name: '分区管理', com: ConfigGroupIndex, args: {}},
        // {code: 'sub2', name: '站点管理', com: ConfigStationIndex, args: {stationType: 3}},
        // {code: 'sub3', name: '通信管理', com: ConfigDeviceIndex, args: {}},
        {code: 'sub4', name: '灯杆管理', com: ConfigPoleIndex, args: {}},
        {code: 'sub5', name: '灯臂类型', com: ArmType, args: {}},
        {code: 'sub6', name: '灯杆类型', com: PoleType, args: {}},
      ]
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    // selectedNode(){
    // },
  },
  mounted: function(){
    // this.$store.commit('group/setDeviceTypes', [this.productCodes.station])
  },
  destroyed: function(){
  },
  methods: {
    switchMenu: function(menu){
      this.tab = menu;
    },
  }
}
</script>
<style scoped>
.pole-container{
  background-color: white;
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  height: 100%;
  align-items: stretch;
  border-radius: 12px;
  /* border: solid 1px red; */
  padding: 10px;
}
.station-left-area{
  width: 300px;
  flex: none;
  margin-right: 10px;
  border: solid 1px rgba(173, 171, 171, 0.651);
}
.station-content-area{
  /* border: solid 1px red; */
  width: 300px;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.pole-header{
  /* border: solid 1px red; */
  height: 40px;
  flex: none;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}
.header-item{
  padding: 0 10px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #152942;
  cursor: pointer;
}
.header-item::after{
  content: '';
  display: block;
  width: 70%;
  height: 4px;
  margin: 0 auto;
  margin-top: 3px;
  border: solid 2px white;
}
.header-active{
  font-weight: bolder;
  position: relative;
}
.header-active::after{
  border: solid 2px #3781FE;
}
.pole-content{
  /* border: solid 1px red; */
  height: 400px;
  flex: auto;
}
.content-item{
  height: 200px;
  flex: auto;
  /* margin-right: 40px; */
  /* border: solid 1px red; */
  overflow: hidden;
}
</style>
