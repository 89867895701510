<template>
  <div class="alarm-rule-container">
    <div class="query-data-area">
      <AuthButton opCode='ptla' size="default" type="primary" @click="handleAdd" style="margin-right: 10px;">新增
      </AuthButton>
      <AuthButton opCode='ptld' size="default" type="primary" @click="deleteParams" style="margin-right: 10px;">批量删除
      </AuthButton>
    </div>
    <div class="alarm-data-area" ref="table1">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :data="list" :height="tabHeight" @checkbox-change="selectChangeEvent" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',}">
        <vxe-column type="seq" width="60" title="序号"></vxe-column>
        <vxe-column width="50" type="checkbox" field="checkbox"></vxe-column>
        <vxe-column field="name" title="名称" header-align="center">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <!-- <vxe-column field="id" title="id" header-align="center">
          <template #default="{ row }">
            {{row.id}}
          </template>
        </vxe-column> -->
        <vxe-column width="260" title="操作" header-align="center">
          <template slot-scope="scope">
            <AuthButton opCode='ptle' style="margin-right: 5px" @click="handleEdit(scope)">编辑</AuthButton>
            <AuthButton opCode='ptld' type="error" @click="handleDelete(scope)">删除</AuthButton>
          </template>
        </vxe-column>
      </vxe-table>
      <ModalPoleEdit v-model="showEditModal" :item="editItem" :stations="selectedStations" @saved="itemSaved" />
      <!-- <ModalArgEdit v-model="showArgModal" /> -->
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ModalPoleEdit from "./ModalPoleEdit";
export default {
  name: "ConfigAreaIndex",
  components: {
    ModalPoleEdit,
    // ModalArgEdit,
  },
  props: {},
  data() {
    return {
      curTab: "hour24",
      list: [],
      tabHeight: 600,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showArgModal: false,
      btns: [
        { code: "updatePowerRated", name: "设定额定功率" },
        { code: "updateCurrentRated", name: "设定额定电流" },
        { code: "updateCurrentRate", name: "设定电流倍率" },
        { code: "updateLeakageRate", name: "设定漏电阈值" },
        { code: "updateSteelRate", name: "设定防盗阈值" },
      ],
    };
  },
  computed: {
    ...mapState("cmd", ["windowOnResize", "cmds"]),
    ...mapState("common", ["weeks", "productCodes", "alarmTypes"]),
    ...mapState("group", ["selectedDevices", "selectedNode"]),
    selectedStations: function () {
      return this.selectedDevices.filter(
        (p) => p.type == this.productCodes.station
      );
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices
        .filter((p) => p.type == this.productCodes.station)
        .map((p) => {
          return p.id;
        });
      return pids;
    },
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    // selectedNode(){
    //   this.getList();
    // }
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    this.getList();
  },
  destroyed: function () { },
  methods: {
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 10;
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params.row };
      this.showEditModal = true;
    },
    itemSaved: function () {
      this.getList();
    },
    deleteParams: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning("请选择要删除的灯杆");
        return;
      }
      let list = chks.map((p) => p.id);
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除已选的[${list.length}]条灯杆吗?`,
        onOk: async () => {
          // this.$store.dispatch("auth/reqData", { method: "DeletePoleTypes", args: { ids: list }, }).then((res) => {

          this.$axios.post(`pole/config/DeletePoleType`, { ids: list }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        },
      });
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除[${params.row.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`pole/config/DeletePoleType`, { ids: [params.row.id] }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        },
      });
    },
    getList: function () {
      this.$axios.post(`pole/config/QueryPoleType`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, "list", res.data);
        }
      });
    },
  },
};
</script>
<style scoped>
.alarm-rule-container {
  height: 100%;
}
.query-data-area {
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  margin: 0px 0 5px 0;
}
.alarm-data-area {
  width: 100%;
  border-radius: 6px;
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>