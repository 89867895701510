<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">编辑信息</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="110">
      <FormItem prop="name" label="名称" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" placeholder="灯臂类型名称"></Input>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'ModalArmEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    stations: {
      type: Array,
      default(){ return [];}
    },
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      form: {
        id: 0,
        name: '',
      },
      rules: {
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.isAdd = this.item.isAdd;
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
        }else{
          this.form.id = 0;
          this.form.name = '';
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
  },
  mounted: function(){
  },
  methods: {
    ok: async function () {
      if(this.form.name.trim().length == 0){
        this.$Message.warning('请输入名称');
        return;
      }
      this.$axios.post(`pole/config/SaveArmType`, this.form ).then(res => {
      // this.$store.dispatch("auth/reqData", { method: "SaveArmType", args: }).then((res) => {
        if(res.code == 0){
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved', {isAdd: this.isAdd, data: res.data});
        }
      })
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>