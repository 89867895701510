<template>
<div class="box-container">
  <div class="box-slider">
    <MyTree class="box-tree" ref="tree" :data="treeData" />
    <List v-if="filter.length > 0" border size="small" class="tree-search-result">
      <template v-if="filterList.length == 0">{{showLang('com.data.no')}}</template>
      <template v-else v-for="(item, idx) in filterList">
        <ListItem v-if="item.type == 'group'" class="node-selected" @click.native="selectIt(item)" :key="idx">{{showLang('com.group')}}：{{item.name}}</ListItem>
        <ListItem v-else class="node-selected" @click.native="selectIt(item)" :key="idx">{{showLang('com.site')}}：[{{item.code}}] {{item.name}}</ListItem>
      </template>
    </List>
  </div>
  <div class="tree-search">
    <img :src="img.treeSearchImg"/>
    <input type="text" v-model="filter" :placeholder="showLang('save.code.name.dev')" />
  </div>
</div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import MyTree from './MyTree'
export default {
  name: 'CommonTreeIndex',
  components: {
    MyTree,
  },
  props: {
    
  },
  data(){
    return {
      showEditGroup: false,
      showChangeGroup: false,
      editItem: {},
      filter:'',
      result: [],
      treeData: [],
    }
  },
  watch: {
    filter(newVal){
      this.$store.commit('group/filterTree', newVal);
    },
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('group', ['devices', 'selectedNode', 'filterList']),
    ...mapState('auth', ['config', 'currentMenuName'])
  },
  mounted: function(){
    // console.log('tree page mounted')
    this.initTreeData();
    window.eventBus.$on('groupChanged', () => {
      // console.log('group data saved');
      this.initTreeData();
      this.initOuterTreeData();
    })
    window.eventBus.$on('stationChanged', () => {
      // console.log('station data saved');
      this.initTreeData();
      // this.initOuterTreeData();
    })
    window.eventBus.$on('poleChanged', () => {
      // console.log('station data saved');
      // this.initTreeData();
      this.initOuterTreeData();
    })
  },
  destroyed: function(){
    // console.log('tree page destroyed')
    window.eventBus.$off('groupChanged');
    window.eventBus.$off('stationChanged');
    window.eventBus.$off('poleChanged');
  },
  methods: {
    initOuterTreeData: function(){
      // console.log('init outer tree data')
      let menu = this.currentMenuName;
      if(menu == 'analyse' || menu == 'asset'){
        menu = 'pole';
      }
      this.$store.dispatch('group/getGroupAndPoles', menu)
    },
    initTreeData: function(){
      // console.log('init tree data')
      let ps = [];
      ps.push(this.$axios.post(`//${this.domains.trans}/station/config/QueryGroup`, {}));
      ps.push(this.$axios.post(`//${this.domains.trans}/station/config/QueryStation`, {type: 3}));
      Promise.all(ps).then(res => {
        // console.log('get promise all data', res, context);
        this.resetPoleTree({
          rootTitle: this.config.name,
          groups: res[0].code == 0 ? res[0].data : [],
          stations: res[1].code == 0 ? res[1].data : []
        });
      })
    },
    getSelectNodeById: function (node, nodeId, type) {
      for (let n of node) {
        if (n.id == nodeId && n.type == type) return n;
        if (!n.children) continue;
        let result = this.getSelectNodeById(n.children, nodeId, type);
        if (result) return result;
      }
      return null;
    },
    selectNodeById: function (state, {nodeId, type}) {
      let node = this.getSelectNodeById(state.treeData, nodeId, type);
      if (node) {
        if (node.type != 'root' && node.type != 'group') {
          state.selectedNode = node;
          state.selectedDevices = [node];
          state.selectedGroups = [];
        } else {
          state.selectedNode = node;
          let devs = [];
          this.getGroupDevices(devs, node);
          state.selectedDevices = devs;
          let grps = [];
          this.getSelectedGroups(grps, state.selectedNode);
          state.selectedGroups = grps;
        }
        state.filterTreeNode = node;
      }
    },
    getTopGroups: function (list) {
      let tops = [];
      for (let g of list) {
        let ps = list.filter(p => p.id == g.parentId);
        if (ps.length == 0) {
          tops.push(g);
        }
      }
      return tops;
    },
    resetPoleTree: function(params){
      let treeData = [{
        id: 0,
        name: params.rootTitle,
        type: 'root',
        expand: true,
        children: []
      }];
      let tops = this.getTopGroups(params.groups);
      let tids = [];
      for (let grp of tops) {
        tids.push(grp.id);
        let n = {
          id: grp.id,
          name: grp.name,
          type: 'group',
          expand: true,
          groupName: params.rootTitle,
          groupId: 0,
          children: []
        };
        treeData[0].children.push(n);
        this.initPoleNodes(n, params.groups, params.stations);
      }
      this.$set(this, 'treeData', treeData);
    },
    initPoleNodes: function (node, groups, stations) {
      stations = stations || [];
      let grps = groups.filter(item => item.parentId == node.id);
      for (let grp of grps) {
        let n = {
          id: grp.id,
          name: grp.name,
          type: 'group',
          expand: true,
          groupName: node.name,
          groupId: node.id,
          children: []
        };
        node.children.push(n);
        this.initPoleNodes(n, groups, stations);
      }
      let ss = stations.filter(p => p.groupId == node.id);
      for(let s of ss){
        let n = {
          type: 'Station',
          expand: true,
          groupName: node.name,
          groupId: node.id,
        };
        Object.assign(n, s);
        node.children.push(n);
      }
    },
    selectIt: function(nodeData){
      this.$store.commit('group/selectNodeById', nodeData.id);
    },
  }
}
</script>
<style scoped>
.box-container{
  background: #ffffff;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  position: relative;
  /* border: solid 1px red; */
}
.box-slider{
  /* border: solid 1px red; */
  flex: none;
  width: 100%;
  /* background-color: #041B32; */
  /* background-color: #ffffff; */
  display: flex;
  flex-direction: column;
}
.box-tree{
  /* border: solid 1px red; */
  flex: auto;
}
.tree-search{
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 20px;
  height: 34px;
  background-color: #EDF3FB;
  /* border-radius: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  /* border: solid 1px red; */
}
.tree-search img{
  /* outline-style: none; */
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.tree-search input{
  outline-style: none;
  width: 210px;
  height: 15px;
  font-size: 14px;
  background-color: transparent;
  border: 0;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #4D6781;
}
.tree-search-result{
  position: absolute;
  bottom: 50px;
  left: 10px;
  right: 0;
  width: 310px;
  background: white;
  max-height: 300px;
  overflow-y: auto;
}
.node-selected{
  cursor: pointer;
}
</style>
